import { EyeIcon, MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import {
  Button,
  ListItem,
  ListItemSuffix,
  Typography,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageDefault } from "../../../common/constants/default.constant";
import { pathApi, pathUrl } from "../../../common/constants/path.constant";
import { PaginationType } from "../../../common/enums/pagination.enum";
import { SortType } from "../../../common/enums/sort-type.enum";
import { MetadataData } from "../../../common/interfaces/httprequest.interface";
import { removeToken } from "../../../common/utils/cookies";
import { HttpRequest } from "../../../common/utils/request";
import { FormInput, FormSelect, SelectData } from "../../../components/forms";
import { TableList } from "../../../components/list";
import PageHeader from "../../../components/page/PageHeader";
import {
  useLoading,
  useMessage,
} from "../../../components/providers/AppProvider";
import {
  ResidentContributionOtherListData,
  ResidentContributionOtherListQueryParam,
} from "./interfaces/list.interface";
import { FormDetail } from "../../../components/forms/detail";
import { Delay } from "../../../common/utils/function";

export default function ResidentContributionOtherPage() {
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();
  const { setMessage } = useMessage();

  const [paramList, setParamList] =
    useState<ResidentContributionOtherListQueryParam>({
      page: PageDefault.Page,
      limit: 10,
      sort_by: "date",
      sort_type: SortType.DESC,
      search: "",
    });
  const [listData, setListData] = useState<ResidentContributionOtherListData[]>(
    []
  );
  const [paginationData, setPaginationData] = useState<MetadataData>({
    total_pages: 0,
    total_rows: 0,
  });
  const [loadingList, setLoadingList] = useState<boolean>(true);

  const getList = async (
    params: ResidentContributionOtherListQueryParam,
    pageBefore?: number
  ) => {
    const { status, data } = await HttpRequest.request<
      ResidentContributionOtherListData[]
    >({
      url: pathApi.contribution.other,
      params,
    });

    if (status !== 200) {
      setMessage({
        show: true,
        message: data.message,
        callback: () => {
          if (status === 401) {
            removeToken();
            navigate(pathUrl.auth.login);
          }
        },
      });
      if (pageBefore) {
        setParamList({ ...paramList, page: pageBefore });
      }
      return;
    }

    setListData(data.data);
    setPaginationData({
      total_pages: data.metadata?.total_pages || 0,
      total_rows: data.metadata?.total_rows || 0,
    });
  };

  const handleSearching = async (value: string) => {
    setParamList({ ...paramList, search: value, page: 1 });
    Delay(async () => {
      showLoading();
      setLoadingList(true);
      await getList({ ...paramList, search: value, page: 1 });
      setLoadingList(false);
      hideLoading();
    }, 250);
  };

  const handlePagination = async (value: PaginationType) => {
    const pagePrev: number = paramList.page - 1;
    const pageNext: number = paramList.page + 1;

    if (value === PaginationType.Previous && pagePrev < 1) {
      return;
    }
    if (
      value === PaginationType.Next &&
      pageNext > paginationData.total_pages
    ) {
      return;
    }

    let pageChoose: number =
      value === PaginationType.Previous ? pagePrev : pageNext;

    showLoading();
    setLoadingList(true);
    setParamList({ ...paramList, page: pageChoose });
    await getList({ ...paramList, page: pageChoose }, paramList.page);
    setLoadingList(false);
    hideLoading();
  };

  useEffect(() => {
    (async () => {
      setLoadingList(true);
      await getList({ ...paramList });
      setLoadingList(false);
      hideLoading();
    })();

    return () => showLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-4 py-4">
      <PageHeader
        left={{
          title: "Iuran Lain",
          subtitle: "Daftar iuran warga lainnya",
        }}
        right={{
          actionAdd: true,
          actionAddTitle: "Baru",
          actionAddUrl: pathUrl.contributionOther.new,
        }}
      />

      {/* Page List */}
      <TableList
        loading={loadingList}
        search={false}
        customSearch={
          <div className="flex flex-col items-center justify-left gap-4 md:flex-row mb-4">
            <div className="w-full">
              <FormInput
                size="lg"
                label="Pencarian:"
                placeholder="Cari nama iuran lainnya"
                value={paramList.search}
                onChange={(e: any) => handleSearching(e.target.value)}
              />
            </div>
          </div>
        }
        dataFound={!!listData.length}
        textNotFound="Cari nama iuran lainnya"
        pagination={{
          page: paramList.page,
          total_pages: paginationData.total_pages,
          action: handlePagination,
        }}
        body={listData.map(
          (item: ResidentContributionOtherListData, index: number) => {
            return (
              <ListItem
                key={index}
                className="mb-2 py-3 bg-blue-gray-50 hover:bg-blue-gray-100 items-start"
                ripple={false}
              >
                <div>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="text-md mb-2"
                  >
                    {item.title}
                  </Typography>
                  <div className="sm:flex sm:flex-row xs:grid">
                    <Typography
                      variant="small"
                      color="gray"
                      className="font-normal text-sm mr-4"
                    >
                      Periode:{" "}
                      <span className="font-semibold">{item.period}</span>
                    </Typography>
                    <Typography
                      variant="small"
                      color="gray"
                      className="font-normal text-sm"
                    >
                      Total Bayar:{" "}
                      <span className="font-semibold">
                        {item.participant.total_paid} / {item.participant.total}
                      </span>
                    </Typography>
                  </div>
                </div>
                <ListItemSuffix>
                  <Button
                    variant="text"
                    size="sm"
                    color="blue-gray"
                    ripple={true}
                    className="px-2 py-1 flex items-center gap-1"
                    onClick={() =>
                      navigate(pathUrl.contributionOther.list + "/" + item.id)
                    }
                  >
                    <EyeIcon strokeWidth={2} className="h-3 w-3" />
                    <Typography
                      variant="small"
                      className="text-sm capitalize font-normal"
                    >
                      Detail
                    </Typography>
                  </Button>
                </ListItemSuffix>
              </ListItem>
            );
          }
        )}
      />
    </div>
  );
}
