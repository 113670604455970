import { colors } from "@material-tailwind/react/types/generic";
import { RoleId } from "../enums/role.enum";

export interface ListMenuProps {
  name: string;
  icon: string;
  color?: colors;
  path: string;
  childs: ListSubMenuProps[];
}
export interface ListSubMenuProps {
  name: string;
  path: string;
}
export const ListMenuAdmin: ListMenuProps[] = [
  {
    name: "Dashboard Pengurus",
    icon: "",
    path: "/",
    childs: [],
  },
  {
    name: "Data Warga",
    icon: "UserGroupIcon",
    color: "light-green",
    path: "/resident",
    childs: [],
  },
  {
    name: "Data Iuran",
    icon: "ClipboardDocumentListIcon",
    color: "red",
    path: "/contribution",
    childs: [
      {
        name: "Iuran IPL",
        path: "/contribution/ipl",
      },
      {
        name: "Iuran Lain",
        path: "/contribution/other",
      },
      {
        name: "Pembayaran",
        path: "/contribution/payment",
      },
    ],
  },
  {
    name: "KAS",
    icon: "BookOpenIcon",
    color: "orange",
    path: "/cash",
    childs: [
      {
        name: "Pengeluaran",
        path: "/cash/out",
      },
      {
        name: "Laporan",
        path: "/cash/report",
      },
    ],
  },
  {
    name: "Administrator",
    icon: "Cog8ToothIcon",
    color: "blue",
    path: "/administrator",
    childs: [
      {
        name: "Pengguna",
        path: "/administrator/user",
      },
      {
        name: "Pengaturan",
        path: "/administrator/setting",
      },
    ],
  },
];
