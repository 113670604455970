import { CheckIcon } from "@heroicons/react/24/solid";
import { Button, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pathApi, pathUrl } from "../../common/constants/path.constant";
import { HttpRequestMethodType } from "../../common/enums/httprequest.enum";
import { HttpRequest } from "../../common/utils/request";
import { FormInput, FormSelect } from "../../components/forms";
import PageHeader from "../../components/page/PageHeader";
import { useLoading, useMessage } from "../../components/providers/AppProvider";
import { ResidentFormData } from "./interfaces/form.interface";
import { ResidentValidation } from "./validations/save.validation";

export default function ResidentForm() {
  let navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();
  const { setMessage } = useMessage();

  const [formData, setFormData] = useState<ResidentFormData>({
    house: {
      block: "",
      number: undefined,
    },
    owner: {
      name: "",
      pob: "",
      dob: "",
      gender: 1,
      religion: "",
      phone: "",
      is_inhabited: 2,
    },
    inhabited: {
      name: "",
      pob: "",
      dob: "",
      gender: 1,
      religion: "",
      phone: "",
    },
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await submitForm();
  };

  const submitForm = async () => {
    showLoading();

    const { messages } = ResidentValidation.save(formData);

    if (messages.length > 0) {
      hideLoading();
      setMessage({
        show: true,
        message: messages,
      });
      return;
    }

    const { status, data } = await HttpRequest.request({
      method: HttpRequestMethodType.POST,
      url: pathApi.resident.list,
      data: formData,
    });
    const isSuccess = [200, 201].includes(status);

    hideLoading();
    setMessage({
      show: true,
      message: data.message,
      callback: () => {
        if (isSuccess) {
          navigate(pathUrl.resident.list);
        }
      },
    });
  };

  const handleChange = (key: string, value: string | number | undefined) => {
    const keys = key.split(".");
    const key1 = keys[0];
    const key2 = keys[1];
    console.log("keys", keys, key1, key2);
    if (key1 === "house") {
      setFormData((prev) => ({
        ...prev,
        house: { ...prev.house, [key2]: value },
      }));
    } else if (key1 === "owner") {
      setFormData((prev) => ({
        ...prev,
        owner: { ...prev.owner, [key2]: value },
      }));
    } else if (key1 === "inhabited") {
      setFormData((prev) => ({
        ...prev,
        inhabited: { ...prev.inhabited, [key2]: value },
      }));
    }
  };

  useEffect(() => {
    hideLoading();

    return () => showLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      inhabited: {
        name: "",
        pob: "",
        dob: "",
        gender: 1,
        religion: "",
        phone: "",
      },
    }));
  }, [formData.owner.is_inhabited]);

  return (
    <div className="px-4 py-4">
      <PageHeader
        left={{
          title: "Tambah Warga Baru",
          actionBack: true,
          actionBackUrl: pathUrl.resident.list,
        }}
      />
      <div className="page-content">
        <form className="" onSubmit={handleSubmit}>
          <div className="max-w-screen-sm">
            <div className="basis-full gap-4">
              <Typography className="mb-4">Data Rumah</Typography>
              <div className="grid grid-cols-2 gap-4">
                <FormInput
                  type="text"
                  label="Blok"
                  classBox="mb-4"
                  value={formData.house.block}
                  onChange={(e: any) =>
                    handleChange("house.block", e.target.value)
                  }
                />
                <FormInput
                  type="number"
                  label="Nomor"
                  classBox="mb-4"
                  value={formData.house.number}
                  onChange={(e: any) =>
                    handleChange("house.number", +e.target.value)
                  }
                />
              </div>
            </div>
            <div className="basis-full gap-4">
              <Typography className="mb-4">Data Pemilik Rumah</Typography>
              <FormInput
                type="text"
                label="Nama"
                placeholder="Nama"
                classBox="mb-4"
                value={formData.owner.name}
                onChange={(e: any) =>
                  handleChange("owner.name", e.target.value)
                }
              />
              <div className="grid grid-cols-2 gap-4">
                <FormInput
                  type="text"
                  label="Tempat Lahir"
                  placeholder="Nama Pengguna"
                  classBox="mb-4"
                  value={formData.owner.pob}
                  onChange={(e: any) =>
                    handleChange("owner.pob", e.target.value)
                  }
                />
                <FormInput
                  type="date"
                  label="Tanggal Lahir"
                  classBox="mb-4"
                  value={formData.owner.dob}
                  onChange={(e: any) =>
                    handleChange("owner.dob", e.target.value)
                  }
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <FormSelect
                  label="Jenis Kelamin"
                  classBox="mb-4"
                  value={formData.owner.gender}
                  defaultValue={formData.owner.gender}
                  onChange={(e: any) => handleChange("gender", e)}
                  data={[
                    { id: 1, name: "Laki-Laki" },
                    { id: 2, name: "Perempuan" },
                  ]}
                />
                <FormSelect
                  label="Agama"
                  classBox="mb-4"
                  value={formData.owner.religion}
                  defaultValue={formData.owner.religion}
                  onChange={(e: any) => handleChange("owner.religion", e)}
                  data={[
                    { id: "1", name: "Islam" },
                    { id: "2", name: "Kristen Katholik" },
                    { id: "3", name: "Kristen Protestan" },
                  ]}
                />
              </div>
              <FormInput
                type="number"
                label="Nomor HP"
                placeholder="08xxxxxxxxx"
                classBox="mb-4"
                value={formData.owner.phone}
                onChange={(e: any) =>
                  handleChange("owner.phone", e.target.value)
                }
              />
              <FormSelect
                label="Penghuni"
                classBox="mb-4"
                value={formData.owner.is_inhabited}
                onChange={(e: any) => handleChange("owner.is_inhabited", +e)}
                data={[
                  { id: 1, name: "YA" },
                  { id: 2, name: "TIDAK" },
                ]}
              />
            </div>
            {formData.owner.is_inhabited === 2 && (
              <div className="basis-full gap-4">
                <Typography className="mb-4">Data Penghuni Rumah</Typography>
                <FormInput
                  type="text"
                  label="Nama"
                  placeholder="Nama"
                  classBox="mb-4"
                  value={formData.inhabited.name}
                  onChange={(e: any) =>
                    handleChange("inhabited.name", e.target.value)
                  }
                />
                <div className="grid grid-cols-2 gap-4">
                  <FormInput
                    type="text"
                    label="Tempat Lahir"
                    placeholder="Nama Pengguna"
                    classBox="mb-4"
                    value={formData.inhabited.pob}
                    onChange={(e: any) =>
                      handleChange("inhabited.pob", e.target.value)
                    }
                  />
                  <FormInput
                    type="date"
                    label="Tanggal Lahir"
                    classBox="mb-4"
                    value={formData.inhabited.dob}
                    onChange={(e: any) =>
                      handleChange("inhabited.dob", e.target.value)
                    }
                  />
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <FormSelect
                    label="Jenis Kelamin"
                    classBox="mb-4"
                    value={formData.inhabited.gender}
                    onChange={(e: any) => handleChange("inhabited.gender", e)}
                    data={[
                      { id: "1", name: "Laki-Laki" },
                      { id: "2", name: "Perempuan" },
                    ]}
                  />
                  <FormSelect
                    label="Agama"
                    classBox="mb-4"
                    value={formData.inhabited.religion}
                    defaultValue={formData.inhabited.religion}
                    onChange={(e: any) => handleChange("inhabited.religion", e)}
                    data={[
                      { id: "1", name: "Islam" },
                      { id: "2", name: "Kristen Katholik" },
                      { id: "3", name: "Kristen Protestan" },
                    ]}
                  />
                </div>
                <FormInput
                  type="number"
                  label="Nomor HP"
                  placeholder="08xxxxxxxxx"
                  classBox="mb-4"
                  value={formData.inhabited.phone}
                  onChange={(e: any) =>
                    handleChange("inhabited.phone", e.target.value)
                  }
                />
              </div>
            )}
          </div>
          <div className="mt-8">
            <Button
              className="flex items-center gap-2"
              variant="gradient"
              size="sm"
              color="blue"
              ripple={true}
              onClick={() => submitForm()}
            >
              <CheckIcon strokeWidth={2} className="h-5 w-5" /> Simpan
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
