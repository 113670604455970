import { PlusIcon } from "@heroicons/react/24/solid";
import { Button, Dialog, ListItem, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pathApi, pathUrl } from "../../../../common/constants/path.constant";
import { removeToken } from "../../../../common/utils/cookies";
import { formatCurrency } from "../../../../common/utils/currency";
import { HttpRequest } from "../../../../common/utils/request";
import { TableList } from "../../../../components/list";
import PageHeader from "../../../../components/page/PageHeader";
import {
  useLoading,
  useMessage,
} from "../../../../components/providers/AppProvider";
import { SettingContributionListData } from "../interfaces/contribution.interface";
import { getPeriod } from "../../../../common/utils/date.util";

export default function SettingContributionPage() {
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();
  const { setMessage } = useMessage();

  const [listData, setListData] = useState<SettingContributionListData[]>([]);
  const [loadingList, setLoadingList] = useState<boolean>(true);

  const getList = async () => {
    const { status, data } = await HttpRequest.request<
      SettingContributionListData[]
    >({
      url: pathApi.setting.contributionIpl,
    });

    if (status !== 200) {
      setMessage({
        show: true,
        message: data.message,
        callback: () => {
          if (status === 401) {
            removeToken();
            navigate(pathUrl.auth.login);
          }
        },
      });
      return;
    }

    setListData(data.data);
  };

  useEffect(() => {
    (async () => {
      showLoading();
      setLoadingList(true);
      await getList();
      setLoadingList(false);
      hideLoading();
    })();

    return () => showLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-4 pt-4 pb-8">
      <PageHeader
        left={{
          title: "Nominal Iuran IPL",
          actionBack: true,
          actionBackUrl: pathUrl.setting.list,
        }}
        right={{
          children: (
            <div className="page-action">
              <Button
                variant="gradient"
                size="sm"
                color="blue"
                ripple={true}
                className="flex items-center gap-2"
                onClick={() => navigate(pathUrl.setting.contributionNew)}
              >
                <PlusIcon strokeWidth={2} className="h-5 w-5" />
                Iuran Baru
              </Button>
            </div>
          ),
        }}
      />

      <TableList
        loading={loadingList}
        dataFound={!!listData.length}
        body={listData.map(
          (item: SettingContributionListData, index: number) => {
            return (
              <ListItem
                key={index}
                className="mb-2 bg-blue-gray-50 hover:bg-blue-gray-100 py-3 items-start"
                ripple={false}
              >
                <div>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="text-md mb-2"
                  >
                    {formatCurrency(item.amount, "Rp ")}
                  </Typography>
                  <Typography variant="small" color="gray" className="text-sm">
                    Mulai Aktif: {getPeriod(item.start_date)}
                  </Typography>
                </div>
              </ListItem>
            );
          }
        )}
      />

      <Dialog
        id="contribute"
        dismiss={{ enabled: false }}
        size="xs"
        open={false}
        className="bg-transparent shadow-none"
        style={{ zIndex: "100" }}
        handler={function (value: any): void {
          throw new Error("Function not implemented.");
        }}
      >
        <Typography>Test</Typography>
      </Dialog>
    </div>
  );
}
