import { ResidentContributionOtherFormData } from "../interfaces/form.interface";

export const ContributionOtherValidation = {
  save: (payload: ResidentContributionOtherFormData) => {
    const { house_type, title, start_date, end_date, amount } = payload;
    const messages: string[] = [];

    if (![1, 2].includes(house_type || 0))
      messages.push("Jenis rumah tidak sesuai.");
    if (title === "") messages.push("Nama Iuran wajib diisi.");
    if (start_date === "") messages.push("Mulai wajib diisi.");
    if (end_date === "") messages.push("Berakhir wajib diisi.");
    if ((amount || 0) < 1) messages.push("Nominal minimal 1.");

    return { messages };
  },
};
