import { Button, Checkbox, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pathApi, pathUrl } from "../../../common/constants/path.constant";
import { HttpRequestMethodType } from "../../../common/enums/httprequest.enum";
import { HttpRequest } from "../../../common/utils/request";
import { FormInput } from "../../../components/forms";
import PageHeader from "../../../components/page/PageHeader";
import {
  useLoading,
  useMessage,
} from "../../../components/providers/AppProvider";
import { ResidentContributionOtherFormData } from "./interfaces/form.interface";
import { ContributionOtherValidation } from "./validations/contribution.other.validation";
import { CheckIcon } from "@heroicons/react/24/solid";
import { removeToken } from "../../../common/utils/cookies";

export default function ResidentContributionOtherForm() {
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();
  const { setMessage } = useMessage();

  const [formData, setFormData] = useState<ResidentContributionOtherFormData>({
    house_type: 1,
    title: "",
    start_date: "",
    end_date: "",
    amount: undefined,
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await submitForm();
  };

  const submitForm = async () => {
    showLoading();

    const { messages } = ContributionOtherValidation.save(formData);

    if (messages.length > 0) {
      hideLoading();
      setMessage({
        show: true,
        message: messages,
      });
      return;
    }

    const { status, data } = await HttpRequest.request({
      method: HttpRequestMethodType.POST,
      url: pathApi.contribution.other,
      data: formData,
    });
    const isSuccess = [200, 201].includes(status);

    hideLoading();
    setMessage({
      show: true,
      message: data.message,
      callback: () => {
        if (isSuccess) {
          navigate(pathUrl.contributionOther.list);
        } else if (status === 401) {
          removeToken();
          navigate(pathUrl.auth.login);
        }
      },
    });
  };

  const handleChange = (key: string, value: string | number | undefined) => {
    setFormData({ ...formData, [key]: value });
  };

  useEffect(() => {
    hideLoading();

    return () => showLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-4 py-4">
      <PageHeader
        left={{
          title: "Tambah Iuran Lain Baru",
          actionBack: true,
          actionBackUrl: pathUrl.contributionOther.list,
        }}
      />
      <div className="page-content">
        <form className="" onSubmit={handleSubmit}>
          <div className="max-w-screen-sm">
            <div className="basis-full gap-4">
              <div className="mb-8">
                <Checkbox
                  label={
                    <div>
                      <Typography color="blue-gray" className="font-normal">
                        {formData.house_type === 1
                          ? "Semua Rumah"
                          : "Rumah Berpenghuni"}
                      </Typography>
                      <Typography
                        variant="small"
                        color="gray"
                        className="text-xs"
                      >
                        Klik centang untuk memilih semua rumah
                      </Typography>
                    </div>
                  }
                  containerProps={{
                    className: "-mt-5",
                  }}
                  crossOrigin={undefined}
                  checked={formData.house_type === 1 ? true : false}
                  value={formData.house_type}
                  onChange={(e) =>
                    handleChange("house_type", e.target.checked ? 1 : 2)
                  }
                />
              </div>
              <FormInput
                type="text"
                label="Nama Iuran"
                classBox="mb-4"
                value={formData.title}
                onChange={(e: any) => handleChange("title", e.target.value)}
              />
              <div className="grid xs:grid-cols-1 sm:grid-cols-2 sm:gap-4">
                <FormInput
                  type="date"
                  label="Mulai"
                  classBox="mb-4"
                  value={formData.start_date}
                  onChange={(e: any) =>
                    handleChange("start_date", e.target.value)
                  }
                />
                <FormInput
                  type="date"
                  label="Berakhir"
                  classBox="mb-4"
                  value={formData.end_date}
                  onChange={(e: any) =>
                    handleChange("end_date", e.target.value)
                  }
                />
              </div>
              <FormInput
                type="number"
                label="Nominal"
                classBox="mb-4"
                value={formData.amount}
                onChange={(e: any) => handleChange("amount", +e.target.value)}
              />
            </div>
          </div>
          <div className="mt-8">
            <Button
              className="flex items-center gap-2"
              variant="gradient"
              size="sm"
              color="blue"
              ripple={true}
              onClick={() => submitForm()}
            >
              <CheckIcon strokeWidth={2} className="h-5 w-5" /> Simpan
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
