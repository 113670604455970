import { EyeIcon } from "@heroicons/react/24/solid";
import {
  Button,
  ListItem,
  ListItemSuffix,
  Typography,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageDefault } from "../../common/constants/default.constant";
import { pathApi, pathUrl } from "../../common/constants/path.constant";
import { PaginationType } from "../../common/enums/pagination.enum";
import { SortType } from "../../common/enums/sort-type.enum";
import { MetadataData } from "../../common/interfaces/httprequest.interface";
import { removeToken } from "../../common/utils/cookies";
import { Delay } from "../../common/utils/function";
import { HttpRequest } from "../../common/utils/request";
import { TableList } from "../../components/list";
import PageHeader from "../../components/page/PageHeader";
import { useLoading, useMessage } from "../../components/providers/AppProvider";
import {
  ResidentListData,
  ResidentListQueryParam,
} from "./interfaces/list.interface";

export default function ResidentPage() {
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();
  const { setMessage } = useMessage();

  const [paramList, setParamList] = useState<ResidentListQueryParam>({
    page: PageDefault.Page,
    limit: 10,
    sort_by: "block_number",
    sort_type: SortType.DESC,
    search: "",
    house_id: -1,
  });
  const [listData, setListData] = useState<ResidentListData[]>([]);
  const [paginationData, setPaginationData] = useState<MetadataData>({
    total_pages: 0,
    total_rows: 0,
  });
  const [loadingList, setLoadingList] = useState<boolean>(true);

  const getList = async (
    params: ResidentListQueryParam,
    pageBefore?: number
  ) => {
    console.log("getList");

    const { status, data } = await HttpRequest.request<ResidentListData[]>({
      url: pathApi.resident.list,
      params,
    });

    if (status !== 200) {
      setMessage({
        show: true,
        message: data.message,
        callback: () => {
          if (status === 401) {
            removeToken();
            navigate(pathUrl.auth.login);
          }
        },
      });
      if (pageBefore) {
        setParamList({ ...paramList, page: pageBefore });
      }
      return;
    }

    setListData(data.data);
    setPaginationData({
      total_pages: data.metadata?.total_pages || 0,
      total_rows: data.metadata?.total_rows || 0,
    });
  };

  const handleSearching = async (value: string) => {
    setParamList({ ...paramList, search: value, page: 1 });
    Delay(async () => {
      showLoading();
      setLoadingList(true);
      await getList({ ...paramList, search: value, page: 1 });
      setLoadingList(false);
      hideLoading();
    }, 250);
  };
  const handlePagination = async (value: PaginationType) => {
    const pagePrev: number = paramList.page - 1;
    const pageNext: number = paramList.page + 1;

    if (value === PaginationType.Previous && pagePrev < 1) {
      return;
    }
    if (
      value === PaginationType.Next &&
      pageNext > paginationData.total_pages
    ) {
      return;
    }

    let pageChoose: number =
      value === PaginationType.Previous ? pagePrev : pageNext;

    showLoading();
    setLoadingList(true);
    setParamList({ ...paramList, page: pageChoose });
    await getList({ ...paramList, page: pageChoose }, paramList.page);
    setLoadingList(false);
    hideLoading();
  };

  useEffect(() => {
    (async () => {
      setLoadingList(true);
      await getList({ ...paramList });
      setLoadingList(false);
      hideLoading();
    })();

    return () => showLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-4 py-4">
      <PageHeader
        left={{
          title: "Warga",
          subtitle: "Daftar warga",
        }}
        right={{
          actionAdd: true,
          actionAddTitle: "Baru",
          actionAddUrl: pathUrl.resident.new,
        }}
      />

      {/* Page List */}
      <TableList
        loading={loadingList}
        search={true}
        defaultSearch={{
          searching: {
            value: paramList.search.toString(),
            placeholder: "Cari nomor rumah atau nama penghuni",
            onChange: (e: any) => {
              handleSearching(e.target.value);
            },
          },
        }}
        dataFound={!!listData.length}
        textNotFound="Cari nama penghuni atau ganti filter"
        pagination={{
          page: paramList.page,
          total_pages: paginationData.total_pages,
          action: handlePagination,
        }}
        body={listData.map((item: ResidentListData, index: number) => {
          const isLast = index === listData.length - 1;
          const tdClasses =
            "px-4 py-2 border-b border-blue-gray-50" +
            (isLast ? " border-b-0" : "");
          return (
            <ListItem
              key={index}
              className="mb-2 bg-blue-gray-50 hover:bg-blue-gray-100 py-3 items-start"
              ripple={false}
            >
              <div>
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="text-md mb-2"
                >
                  {item.block_number}
                </Typography>
                <Typography
                  variant="small"
                  color="gray"
                  className={
                    "font-normal text-sm " +
                    (item.inhabited > 0 ? "text-teal-500" : "text-red-500")
                  }
                >
                  {item.inhabited_name}
                </Typography>
              </div>
              <ListItemSuffix>
                <Button
                  variant="text"
                  size="sm"
                  color="blue-gray"
                  className="px-2 py-1 flex items-center gap-1"
                  onClick={() =>
                    navigate(pathUrl.resident.list + "/" + item.id)
                  }
                >
                  <EyeIcon strokeWidth={2} className="h-3 w-3" />
                  <Typography
                    variant="small"
                    className="text-sm capitalize font-normal"
                  >
                    Detail
                  </Typography>
                </Button>
              </ListItemSuffix>
            </ListItem>
          );
        })}
      />
    </div>
  );
}
