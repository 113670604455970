import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { pathApi, pathUrl } from "../../common/constants/path.constant";
import { ResponseData } from "../../common/interfaces/httprequest.interface";
import { HttpRequest } from "../../common/utils/request";
import PageHeader from "../../components/page/PageHeader";
import { useLoading, useMessage } from "../../components/providers/AppProvider";
import { ResidentDetailData } from "./interfaces/list.interface";
import { Button, Typography } from "@material-tailwind/react";
import { FormDetail } from "../../components/forms/detail";
import {
  ArrowPathIcon,
  PencilIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/solid";

export default function ResidentDetail() {
  let navigate = useNavigate();
  const params = useParams<{ houseId: string }>();
  const { showLoading, hideLoading } = useLoading();
  const { setMessage } = useMessage();

  const [detailData, setDetailData] = useState<ResidentDetailData>({
    id: 0,
    block_number: "",
    owner: undefined,
    inhabited: undefined,
  });

  useEffect(() => {
    (async () => {
      let status = 404;
      let data: ResponseData<ResidentDetailData> = {
        message: "ID warga salah",
        data: detailData,
      };
      const houseId: number = +(params.houseId || 0);
      if (houseId > 0) {
        const requestData = await HttpRequest.request<ResidentDetailData>({
          url: `${pathApi.resident.list}/${houseId}`,
        });

        status = requestData.status;
        data = {
          message: requestData.data.message,
          data: {
            ...detailData,
            ...requestData.data.data,
          },
        };
      }

      const isSuccess = [200, 201].includes(status);
      if (!isSuccess) {
        hideLoading();
        setMessage({
          show: true,
          message: data.message,
          callback: () => {
            navigate(pathUrl.resident.list);
          },
        });
        return;
      }

      setDetailData({
        ...detailData,
        ...data.data,
      });
      hideLoading();
    })();

    return () => showLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-4 pt-4 pb-8">
      <PageHeader
        left={{
          title: `Detail Warga ${detailData.block_number}`,
          actionBack: true,
          actionBackUrl: pathUrl.resident.list,
        }}
      />
      <div className="page-content">
        <div>
          <div className="mb-8">
            <Typography variant="lead" className="font-light mb-2">
              Info Pemilik
            </Typography>
            <div className="border bbg-gray-200 px-4 py-6 rounded-md">
              <div className="grid md:grid-cols-4 sm:grid-cols-2 gap-4 mb-4">
                <FormDetail
                  label="Nama"
                  value={detailData.owner?.name}
                  className="border-none"
                />
                <FormDetail
                  label="Jenis Kelamin"
                  value={detailData.owner?.gender_text}
                  className="border-none"
                />
                <FormDetail
                  label="Tempat Lahir"
                  value={detailData.owner?.pob}
                  className="border-none"
                />
                <FormDetail
                  label="Tanggal Lahir"
                  value={detailData.owner?.dob_text}
                  className="border-none"
                />
                <FormDetail
                  label="Agama"
                  value={detailData.owner?.religion_text}
                  className="border-none"
                />
                <FormDetail
                  label="No. HP"
                  value={detailData.owner?.phone}
                  className="border-none"
                />
                {detailData.owner?.inhabited === 1 && (
                  <FormDetail
                    label="Penghuni"
                    value="YA"
                    className="border-none"
                  />
                )}
              </div>
              <div className="flex flex-row gap-2">
                <Button
                  className="flex items-center gap-2 text-sm px-2 py-1 capitalize font-normal"
                  variant="text"
                  size="sm"
                  color="green"
                  ripple={true}
                >
                  <PencilIcon strokeWidth={2} className="h-3 w-3" /> Edit
                </Button>
                <Button
                  className="flex items-center gap-2 text-sm px-2 py-1 capitalize font-normal"
                  variant="text"
                  size="sm"
                  color="blue-gray"
                  ripple={true}
                >
                  <ArrowPathIcon strokeWidth={2} className="h-3 w-3" /> Ganti
                </Button>
              </div>
            </div>
          </div>
          <div className="">
            <Typography variant="lead" className="font-light mb-2">
              Info Penghuni
            </Typography>
            {detailData.owner?.inhabited !== 1 ? (
              <div className="border bbg-gray-200 px-4 py-6 rounded-md">
                <div className="grid md:grid-cols-4 sm:grid-cols-2 gap-4 mb-4">
                  <FormDetail
                    label="Nama"
                    value={detailData.inhabited?.name}
                    className="border-none"
                  />
                  <FormDetail
                    label="Jenis Kelamin"
                    value={detailData.inhabited?.gender_text}
                    className="border-none"
                  />
                  <FormDetail
                    label="Tempat Lahir"
                    value={detailData.inhabited?.pob}
                    className="border-none"
                  />
                  <FormDetail
                    label="Tanggal Lahir"
                    value={detailData.inhabited?.dob_text}
                    className="border-none"
                  />
                  <FormDetail
                    label="Agama"
                    value={detailData.inhabited?.religion_text}
                    className="border-none"
                  />
                  <FormDetail
                    label="No. HP"
                    value={detailData.inhabited?.phone}
                    className="border-none"
                  />
                </div>
                <div className="flex flex-row gap-2">
                  <Button
                    className="flex items-center gap-2 text-sm px-2 py-1 capitalize font-normal"
                    variant="text"
                    size="sm"
                    color="green"
                    ripple={true}
                  >
                    <PencilIcon strokeWidth={2} className="h-3 w-3" /> Edit
                  </Button>
                  <Button
                    className="flex items-center gap-2 text-sm px-2 py-1 capitalize font-normal"
                    variant="text"
                    size="sm"
                    color="blue-gray"
                    ripple={true}
                  >
                    <ArrowPathIcon strokeWidth={2} className="h-3 w-3" /> Ganti
                  </Button>
                </div>
              </div>
            ) : (
              <div className="flex flex-row gap-2">
                <Button
                  className="flex items-center gap-2 text-sm px---2 py---1 capitalize font-normal"
                  variant="text"
                  size="sm"
                  color="blue-gray"
                  ripple={true}
                >
                  <ArrowPathIcon strokeWidth={2} className="h-3 w-3" /> Ganti
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
