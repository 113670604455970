import { SettingContributionFormData } from "../../interfaces/contribution.interface";

export const SettingContributionValidation = {
  save: (payload: SettingContributionFormData) => {
    const { start_date, contribution_id } = payload;
    const messages: string[] = [];

    if (start_date === "") messages.push("Tanggal wajib diisi.");
    if (contribution_id < 1) messages.push("Nominal wajib dipilih.");

    return { messages };
  },
};
