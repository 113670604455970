import { useEffect } from "react";
import { pathUrl } from "../../../common/constants/path.constant";
import PageHeader from "../../../components/page/PageHeader";
import { useLoading } from "../../../components/providers/AppProvider";

export default function CashOutPage() {
  const { showLoading, hideLoading } = useLoading();

  useEffect(() => {
    (async () => {
      hideLoading();
    })();

    return () => showLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-4 py-4">
      <PageHeader
        left={{
          title: "Pengeluaran",
          subtitle: "Daftar pengeluaran KAS",
        }}
        right={{
          actionAdd: true,
          actionAddTitle: "Baru",
          actionAddUrl: pathUrl.resident.new,
        }}
      />
    </div>
  );
}
