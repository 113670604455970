import moment from "moment";

export const dateToText = (date: string): string => {
  const arrDate: string[] = date.split("-");
  const monthName: string = getMonth(+arrDate[1]);
  return `${arrDate[2]} ${monthName} ${arrDate[0]}`;
};

export const dateToDay = (day: number): string => {
  return getDay(day);
};

export const getPeriod = (date: string): string => {
  const arrDate: string[] = date.split("-");
  const month = getMonth(+arrDate[1]);
  return `${month} ${arrDate[0]}`;
};

const getMonth = (month: number): string => {
  let monthName = "";
  switch (month) {
    case 12:
      monthName = "Desember";
      break;
    case 11:
      monthName = "November";
      break;
    case 10:
      monthName = "Oktober";
      break;
    case 9:
      monthName = "September";
      break;
    case 8:
      monthName = "Agustus";
      break;
    case 7:
      monthName = "Juli";
      break;
    case 6:
      monthName = "Juni";
      break;
    case 5:
      monthName = "Mei";
      break;
    case 4:
      monthName = "April";
      break;
    case 3:
      monthName = "Maret";
      break;
    case 2:
      monthName = "Pebruari";
      break;
    default:
      monthName = "Januari";
      break;
  }
  return monthName;
};

const getDay = (day: number): string => {
  let dayName = "";
  switch (day) {
    case 6:
      dayName = "Sabtu";
      break;
    case 5:
      dayName = "Jum'at";
      break;
    case 4:
      dayName = "Kamis";
      break;
    case 3:
      dayName = "Rabu";
      break;
    case 2:
      dayName = "Selasa";
      break;
    case 1:
      dayName = "Senin";
      break;
    default:
      dayName = "Minggu";
      break;
  }
  return dayName;
};
