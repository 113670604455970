import {
  ResidentFormData,
  ResidentInhabitedData,
  ResidentOwnerData,
} from "../interfaces/form.interface";

export const ResidentValidation = {
  save: (payload: ResidentFormData) => {
    const { house, owner, inhabited } = payload;
    const messages: string[] = [];

    if (house.block === "") messages.push("Blok Rumah wajib diisi.");
    if (+(house.number || 0) < 1) messages.push("Nomor Rumah wajib diisi.");
    checkResident(messages, owner, undefined);
    if (owner.is_inhabited === 2) {
      checkResident(messages, undefined, inhabited);
    }

    return { messages };
  },
};

const checkResident = (
  messages: string[],
  ownerData?: ResidentOwnerData,
  inhabitedData?: ResidentInhabitedData
) => {
  const resident = ownerData ? "Pemilik" : "Penghuni";
  if (!(ownerData || inhabitedData)?.name)
    messages.push(`Nama ${resident} wajib diisi.`);
  if (!(ownerData || inhabitedData)?.pob)
    messages.push(`Tempat Lahir ${resident} wajib diisi.`);
  if (!(ownerData || inhabitedData)?.dob)
    messages.push(`Tanggal Lahir ${resident} wajib diisi.`);
  if (!(ownerData || inhabitedData)?.gender)
    messages.push(`Kelamin ${resident} wajib diisi.`);
  if (!(ownerData || inhabitedData)?.religion)
    messages.push(`Agama ${resident} wajib diisi.`);
  if (!(ownerData || inhabitedData)?.phone)
    messages.push(`No. HP ${resident} wajib diisi.`);

  return messages;
};
