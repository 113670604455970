import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pathApi, pathUrl } from "../../../common/constants/path.constant";
import { removeToken } from "../../../common/utils/cookies";
import { HttpRequest } from "../../../common/utils/request";
import PageHeader from "../../../components/page/PageHeader";
import {
  useLoading,
  useMessage,
} from "../../../components/providers/AppProvider";
import SettingContributionList from "./components/SettingContributionList";
import { SettingListData } from "./interfaces/index.interface";
import { SettingType } from "../../../common/enums/setting.enum";
import SettingModal from "./SettingModal";
import { SettingModalData } from "./interfaces/modal.interface";

export default function SettingPage() {
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();
  const { setMessage } = useMessage();

  const [settingData, setSettingData] = useState<SettingListData>({
    contribution: {
      amount: 0,
      due_date: 5,
    },
  });
  /**
   * state modal
   */
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<SettingModalData>({
    type: undefined,
  });

  const handleOpenModalDueDate = (type: SettingType, due_date: number) => {
    setModalData({ ...modalData, type, due_date });
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setModalData({ ...modalData, type: undefined, due_date: 0 });
  };

  const getSettingContribution = async () => {
    showLoading();
    const requestData = await HttpRequest.request<SettingListData[]>({
      url: pathApi.setting.list,
    });

    if (requestData.status !== 200) {
      hideLoading();
      setMessage({
        show: true,
        message: requestData.data.message,
        callback: () => {
          if (requestData.status === 401) {
            removeToken();
            navigate(pathUrl.auth.login);
          }
        },
      });
      return;
    }

    setSettingData({ ...settingData, ...requestData.data.data });
    hideLoading();
  };

  useEffect(() => {
    (async () => {
      await getSettingContribution();
    })();

    return () => showLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-4 py-4">
      <PageHeader
        left={{
          title: "Pengaturan Umum",
        }}
      />
      <div>
        <SettingContributionList
          data={settingData.contribution}
          handleOpenModal={handleOpenModalDueDate}
        />
      </div>
      <SettingModal
        data={modalData}
        open={openModal}
        onClose={handleCloseModal}
        refreshData={getSettingContribution}
      />
    </div>
  );
}
