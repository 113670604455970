import { PencilIcon } from "@heroicons/react/24/solid";
import {
  Button,
  List,
  ListItem,
  ListItemSuffix,
  Typography,
} from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { pathUrl } from "../../../../common/constants/path.constant";
import { formatCurrency } from "../../../../common/utils/currency";
import {
  useLoading,
  useMessage,
} from "../../../../components/providers/AppProvider";
import { SettingContributionData } from "../interfaces/index.interface";
import { SettingType } from "../../../../common/enums/setting.enum";

interface ContributionListProps {
  data: SettingContributionData;
  handleOpenModal?: Function;
}

export default function SettingContributionList(props: ContributionListProps) {
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();
  const { setMessage } = useMessage();

  const handleOpenModal = async (type: SettingType) => {
    props.handleOpenModal && props.handleOpenModal(type, props.data.due_date);
  };

  return (
    <div className="md:flex md:justity-between md:items-start w-full">
      <div className="mr-auto w-full xs:mb-4 sm:mb-4">
        <Typography variant="lead" className="text-lg">
          Iuran IPL
        </Typography>
        <Typography variant="small" className="text-sm">
          Pengaturan iuran IPL warga
        </Typography>
      </div>

      <List className="p-0 w-full min-w-[60%]">
        <ListItem
          key={1}
          className="mb-2 bg-blue-gray-50 hover:bg-blue-gray-100 items-start"
          ripple={false}
        >
          <div>
            <Typography
              variant="small"
              color="blue-gray"
              className="text-sm mb-2"
            >
              Nominal Iuran IPL
            </Typography>
            <Typography variant="small" color="blue-gray" className="text-sm">
              {formatCurrency(props.data.amount || 0, "Rp ")}
            </Typography>
          </div>
          <ListItemSuffix className="flex flex-row">
            <Button
              variant="text"
              size="sm"
              color="green"
              ripple={true}
              className="px-2 py-1 flex items-center gap-1"
              onClick={() => navigate(pathUrl.setting.contribution)}
            >
              <PencilIcon strokeWidth={2} className="h-3 w-3" />
              <Typography
                variant="small"
                className="text-sm capitalize font-normal"
              >
                Atur
              </Typography>
            </Button>
          </ListItemSuffix>
        </ListItem>
        <ListItem
          key={2}
          className="mb-2 bg-blue-gray-50 hover:bg-blue-gray-100 items-start"
          ripple={false}
        >
          <div>
            <Typography
              variant="small"
              color="blue-gray"
              className="text-sm mb-2"
            >
              Batas Waktu Bayar Iuran IPL
            </Typography>
            <Typography variant="small" color="blue-gray" className="text-sm">
              Tanggal {props.data.due_date || "-"}
            </Typography>
          </div>
          <ListItemSuffix>
            <Button
              variant="text"
              size="sm"
              color="green"
              ripple={true}
              className="px-3 py-1 flex items-center gap-1"
              onClick={() => handleOpenModal(SettingType.IPL_DUE)}
            >
              <PencilIcon strokeWidth={2} className="h-3 w-3" />
              <Typography
                variant="small"
                className="text-sm capitalize font-normal"
              >
                Atur
              </Typography>
            </Button>
          </ListItemSuffix>
        </ListItem>
      </List>
    </div>
  );
}
