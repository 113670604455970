import moment from "moment";
import { PageTitle } from "../constants/page.constant";
import {
  ListMenuAdmin,
  ListMenuProps,
  ListSubMenuProps,
} from "../constants/menu.constant";
import { SelectData } from "../../components/forms";

export const Delay = (() => {
  let timer = 0;
  return (callback: Function, ms: number) => {
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();

export const getMonthName = (month: number) => {
  let monthName = "";
  switch (month) {
    case 12:
      monthName = "Desember";
      break;
    case 11:
      monthName = "November";
      break;
    case 10:
      monthName = "Oktober";
      break;
    case 9:
      monthName = "September";
      break;
    case 8:
      monthName = "Agustus";
      break;
    case 7:
      monthName = "Juli";
      break;
    case 6:
      monthName = "Juni";
      break;
    case 5:
      monthName = "Mei";
      break;
    case 4:
      monthName = "April";
      break;
    case 3:
      monthName = "Maret";
      break;
    case 2:
      monthName = "Februari";
      break;
    case 1:
      monthName = "Januari";
      break;
    default:
      monthName = "";
  }
  return monthName;
};

export const GetPageTitle = (pathname: string): string => {
  const arrPath = pathname.split("/");
  return PageTitle[arrPath[1].replace("-", "") || "navigation"];
};

export const SetTimerOtp = (datetime: number): number => {
  const startTime = moment(Date.now());
  const endTime = moment(datetime);
  const diffInSeconds = endTime.diff(startTime, "seconds");
  return diffInSeconds < 1 ? 0 : diffInSeconds;
};

export const GetUserMenu = (role_id: number): ListMenuProps[] => {
  return ListMenuAdmin;
};

export const GetUserSubmenu = (
  role_id: number,
  path: string
): ListSubMenuProps[] => {
  return (
    GetUserMenu(role_id).find(
      (menu) =>
        path.indexOf(menu.path) === 0 &&
        menu.childs.length > 0 &&
        menu.childs.find((submenu) => path.indexOf(submenu.path) === 0)
    )?.childs || []
  );
};

export const GetDropdownYear = (): SelectData[] => {
  const result: SelectData[] = [];
  const startYear = 2025;
  const currentYear = moment().format("YYYY");
  for (let x = startYear; x <= +currentYear; x++) {
    result.push({
      id: x,
      name: x.toString(),
    });
  }
  return result;
};

export const GetDropdownHouseType = (): SelectData[] => {
  return [
    { id: 1, name: "Semua Rumah" },
    { id: 2, name: "Rumah Berpenghuni" },
  ];
};
