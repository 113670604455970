import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Card,
  CardBody,
  Dialog,
  Typography,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pathApi } from "../../../common/constants/path.constant";
import { HttpRequestMethodType } from "../../../common/enums/httprequest.enum";
import { SettingType } from "../../../common/enums/setting.enum";
import { HttpRequest } from "../../../common/utils/request";
import { FormInput } from "../../../components/forms";
import {
  useLoading,
  useMessage,
} from "../../../components/providers/AppProvider";
import {
  FormSettingDueDateData,
  SettingModalData,
} from "./interfaces/modal.interface";

interface SettingModalProps {
  data: SettingModalData;
  open: boolean;
  onClose: Function;
  refreshData: Function;
}

export default function SettingModal(props: SettingModalProps) {
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();
  const { setMessage } = useMessage();

  const [formData, setFormData] = useState<FormSettingDueDateData>({
    due_date: undefined,
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await submitForm();
  };

  const submitForm = async () => {
    if (props.data.type === SettingType.IPL_DUE) {
      if (!formData.due_date || formData.due_date < 1) {
        setMessage({
          show: true,
          message: "Tanggal wajib diisi",
        });
        return;
      }
    }

    showLoading();

    const { status, data } = await HttpRequest.request({
      method: HttpRequestMethodType.POST,
      url: pathApi.setting.contributionDue,
      data: formData,
    });
    const isSuccess = [200, 201].includes(status);
    if (isSuccess) {
      closeModal();
    }

    hideLoading();
    setMessage({
      show: true,
      message: data.message,
      callback: () => {
        if (isSuccess) {
          props.refreshData();
        }
      },
    });
  };

  const handleChange = (key: string, value: string | number) => {
    setFormData({ ...formData, [key]: value });
  };

  const closeModal = () => {
    props.onClose();
  };

  useEffect(() => {
    if (props.data.type === SettingType.IPL_DUE) {
      setFormData({ ...formData, due_date: props.data.due_date });
    }
  }, [props.data]);

  return (
    <Dialog
      id="contribute"
      dismiss={{ enabled: false }}
      size={props.data.type === SettingType.IPL_DUE ? "xs" : "md"}
      open={props.open}
      handler={closeModal}
      className="bg-transparent shadow-none"
      style={{ zIndex: "100" }}
    >
      <Card className="mx-auto w-full">
        <CardBody className="flex flex-col">
          <form onSubmit={handleSubmit}>
            <Typography variant="lead" className="mb-0 text-lg">
              Atur Batas Waktu
            </Typography>
            <Typography variant="small" className="mb-8">
              Tentukan tanggal batas waktu bayar iuran IPL
            </Typography>

            <div className="mb-8">
              <FormInput
                type="number"
                label="Tanggal"
                value={formData?.due_date}
                onChange={(e: any) => handleChange("due_date", +e.target.value)}
              />
            </div>

            <div className="flex flex-row gap-2 justify-end">
              <Button
                type="submit"
                variant="gradient"
                size="sm"
                color="blue"
                ripple={true}
                className="flex items-center gap-1"
              >
                <CheckIcon strokeWidth={2} className="h-3 w-3" />
                <Typography
                  variant="small"
                  className="text-sm capitalize font-normal"
                >
                  Simpan
                </Typography>
              </Button>
              <Button
                variant="text"
                size="sm"
                color="blue-gray"
                ripple={true}
                className="flex items-center gap-1"
                onClick={() => closeModal()}
              >
                <XMarkIcon strokeWidth={2} className="h-3 w-3" />
                <Typography
                  variant="small"
                  className="text-sm capitalize font-normal"
                >
                  Tutup
                </Typography>
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    </Dialog>
  );
}
