import { Option, Select } from "@material-tailwind/react";
import { variant } from "@material-tailwind/react/types/components/select";
import { ReactNode } from "react";

export interface SelectData {
  id: number | string;
  name: string;
}
interface FormSelectProps {
  size?: string | undefined;
  label?: string | undefined;
  key?: string | undefined;
  variant?: variant;
  value?: string | number | undefined;
  defaultValue?: string | number | undefined;
  onChange?: Function | undefined;
  children?: ReactNode;
  disabled?: boolean;
  data?: SelectData[];
  className?: string;
  classBox?: string;
}
export function FormSelect(props: FormSelectProps) {
  const handleChange = (e: any) => {
    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <div className={props.classBox || ""}>
      {/* <Typography
        variant="small"
        className="mb-2 text-left font-semibold !text-gray-900"
      >
        {props.label}
      </Typography> */}
      <Select
        // key={props.key}
        label={props.label}
        size={props?.size === "md" ? "md" : "lg"}
        variant={props.variant ? props.variant : "outlined"}
        defaultValue={props?.value?.toString()}
        value={props?.value?.toString()}
        onChange={handleChange}
        className={"p-1 " + (props.className || "")}
        color="blue-gray"
        // className="!border-t-blue-gray-200 focus:!border-t-gray-900"
        // labelProps={{
        //   className: "before:content-none after:content-none",
        // }}
        disabled={props?.disabled}
      >
        {/* {props.children || ''} */}
        {props.data &&
          props.data.map((opt) => (
            <Option key={opt.id.toString()} value={opt.id.toString()}>
              {opt.name}
            </Option>
          ))}
      </Select>
      {/* <select
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={handleChange}
        disabled={props.disabled}
        className="w-full rounded-[7px] border border-blue-gray-200 border-t-transparents bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-gray-900 focus:border focus:border-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
      >
        {props.children
          ? props.children
          : props.data?.map(({ id, name }: SelectData) => (
              <React.Fragment key={(props?.key || "key") + id}>
                <option value={id.toString()}>{name}</option>
              </React.Fragment>
            ))}
      </select> */}
    </div>
  );
}
