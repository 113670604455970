import { CheckIcon } from "@heroicons/react/24/solid";
import { Button, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pathApi, pathUrl } from "../../../../common/constants/path.constant";
import { formatCurrency } from "../../../../common/utils/currency";
import { FormInput, FormSelect } from "../../../../components/forms";
import PageHeader from "../../../../components/page/PageHeader";
import {
  useLoading,
  useMessage,
} from "../../../../components/providers/AppProvider";
import {
  ContributionItemData,
  DropdownContributionData,
  SettingContributionFormData,
} from "../interfaces/contribution.interface";
import { HttpRequestMethodType } from "../../../../common/enums/httprequest.enum";
import { HttpRequest } from "../../../../common/utils/request";
import { SettingContributionValidation } from "./validations/save.validation";

export default function SettingContributionForm() {
  let navigate = useNavigate();
  const { showLoading, hideLoading } = useLoading();
  const { setMessage } = useMessage();

  const [formData, setFormData] = useState<SettingContributionFormData>({
    start_date: "",
    contribution_id: 0,
  });

  const [dropdownContributionData, setDropdownContributionData] = useState<
    DropdownContributionData[]
  >([
    {
      id: 1,
      name: "Rp 100.000",
      items: [
        { item_name: "Keamanan", amount: 75000 },
        { item_name: "Keberihan", amount: 20000 },
        { item_name: "Kerohanian", amount: 5000 },
      ],
    },
    {
      id: 2,
      name: "Rp 75.000",
      items: [{ item_name: "Keamanan", amount: 75000 }],
    },
  ]);
  const [constributionItemData, setConstributionItemData] = useState<
    ContributionItemData[]
  >([]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await submitForm();
  };

  const submitForm = async () => {
    showLoading();

    const { messages } = SettingContributionValidation.save(formData);

    if (messages.length > 0) {
      hideLoading();
      setMessage({
        show: true,
        message: messages,
      });
      return;
    }

    const { status, data } = await HttpRequest.request({
      method: HttpRequestMethodType.POST,
      url: pathApi.setting.contributionIpl,
      data: formData,
    });
    const isSuccess = [200, 201].includes(status);

    hideLoading();
    setMessage({
      show: true,
      message: data.message,
      callback: () => {
        if (isSuccess) {
          navigate(pathUrl.setting.contribution);
        }
      },
    });
  };

  const getDropdownContribution = async () => {
    const { status, data } = await HttpRequest.request<
      DropdownContributionData[]
    >({
      method: HttpRequestMethodType.GET,
      url: pathApi.dropdown.contribution,
    });

    if (status !== 200) {
      setMessage({
        show: true,
        message: data.message,
        callback: () => {
          if (status === 401) {
            navigate(pathUrl.auth.login);
          }
        },
      });
    }

    if (status === 200) {
      setDropdownContributionData(data.data);
    }
  };

  const handleChange = (key: string, value: string | number) => {
    setFormData({ ...formData, [key]: value });
  };

  const chooseContribution = (contribution_id: number) => {
    handleChange("contribution_id", contribution_id);
    const contributionData = dropdownContributionData.find(
      (i) => i.id === contribution_id
    );
    setConstributionItemData(contributionData?.items || []);
  };

  useEffect(() => {
    (async () => {
      await getDropdownContribution();
      hideLoading();
    })();

    return () => showLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-4 pt-4 pb-8">
      <PageHeader
        left={{
          title: "Tambah Iuran IPL Baru",
          actionBack: true,
          actionBackUrl: pathUrl.setting.contribution,
        }}
      />
      <div className="page-content">
        <form onSubmit={handleSubmit}>
          <div className="max-w-screen-sm">
            <div className="grid grid-cols-2">
              <FormInput
                type="date"
                label="Mulai Aktif"
                classBox="mb-4"
                value={formData.start_date}
                onChange={(e: any) =>
                  handleChange("start_date", e.target.value)
                }
              />
            </div>
            <div className="grid grid-cols-2">
              <FormSelect
                label="Nominal"
                classBox="mb-4"
                data={dropdownContributionData}
                onChange={(e: any) => chooseContribution(+(e || 0))}
              />
            </div>
            {constributionItemData.length > 0 && (
              <div className="mb-4">
                <Typography variant="paragraph" className="mb-2 text-sm">
                  Detail Item
                </Typography>
                <div className="border rounded-lg p-4 pb-2">
                  {constributionItemData.map((item) => (
                    <div className="flex justify-between mb-2">
                      <Typography variant="small" className="text-sm">
                        {item.item_name}
                      </Typography>
                      <Typography variant="small" className="text-sm">
                        {formatCurrency(item.amount, "Rp ")}
                      </Typography>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="pt-4">
              <Button
                type="submit"
                className="flex items-center gap-2"
                variant="gradient"
                size="sm"
                color="blue"
                ripple={true}
              >
                <CheckIcon strokeWidth={2} className="h-5 w-5" /> Simpan
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
