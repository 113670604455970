import { Outlet, useLocation } from "react-router-dom";
import Loader from "../loader/Loader";
import Message from "../message/Message";
import Header from "./Header";
import { GetUserSubmenu } from "../../common/utils/function";
import { useLoading, useMessage } from "../providers/AppProvider";
import { useEffect } from "react";

export default function Layout() {
  const location = useLocation();
  const { messageData } = useMessage();
  const { isLoading, showLoading } = useLoading();

  return (
    <>
      <div id="layout" className="min-h-screen w-full flex flex-col">
        <div className="w-full fixed top-0 left-0 right-0 z-30">
          <Header />
        </div>
        <div
          className={
            "w-full lg:w-9/12 mx-auto " +
            (GetUserSubmenu(1, location.pathname).length > 1
              ? "pt-24 mt-2"
              : "pt-16")
          }
        >
          <Outlet />
        </div>
      </div>
      {isLoading && <Loader />}
      {messageData.show && <Message />}
    </>
  );
}
