import {
  BookOpenIcon,
  ClipboardDocumentListIcon,
  Cog8ToothIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid";
import { Button, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { ListMenuProps } from "../../common/constants/menu.constant";
import { ReactNode } from "react";

export function IconMenu(props: ListMenuProps) {
  const navigate = useNavigate();

  const getButtonMenu = (param: ListMenuProps): ReactNode => {
    let path = param.path;
    if (param.childs.length > 0) {
      path = param.childs[0].path;
    }
    return (
      <Button
        key={path}
        size="lg"
        variant="gradient"
        color={param.color}
        className="text-center"
        onClick={() => navigate(path)}
      >
        {getIcon(param.icon)}
        <Typography color="white">{param.name}</Typography>
      </Button>
    );
  };

  const getIcon = (icon: string): ReactNode => {
    switch (icon) {
      case "UserGroupIcon":
        return (
          <UserGroupIcon className="h-12 w-12 mx-auto mb-2" color="white" />
        );
        break;
      case "ClipboardDocumentListIcon":
        return (
          <ClipboardDocumentListIcon
            className="h-12 w-12 mx-auto mb-2"
            color="white"
          />
        );
        break;
      case "Cog8ToothIcon":
        return (
          <Cog8ToothIcon className="h-12 w-12 mx-auto mb-2" color="white" />
        );
        break;

      case "BookOpenIcon":
        return (
          <BookOpenIcon className="h-12 w-12 mx-auto mb-2" color="white" />
        );
        break;

      default:
        break;
    }
  };
  return <>{getButtonMenu(props)}</>;
}
