// import * as React from 'react';
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";
import { pathUrl } from "../common/constants/path.constant";
import { checkAuth } from "../common/utils/cookies";
import LayoutAuth from "../components/layout/LayoutAuth";
import AuthLoginPage from "../pages/auth/Login";
import AuthLoginVerifyPage from "../pages/auth/LoginVerify";
import NavigationPage from "../pages/navigation";
import Layout from "../components/layout/Layout";
import ResidentPage from "../pages/resident";
import UserPage from "../pages/administrator/user";
import UserForm from "../pages/administrator/user/Form";
import ResidentForm from "../pages/resident/Form";
import ResidentFeeIplPage from "../pages/contribution/ipl";
import ResidentContributionOtherPage from "../pages/contribution/other";
import HousePage from "../pages/administrator/house";
import HouseForm from "../pages/administrator/house/Form";
import SettingPage from "../pages/administrator/setting";
import ResidentDetail from "../pages/resident/Detail";
import CashOutPage from "../pages/cash/out";
import CashReportPage from "../pages/cash/report";
import SettingContributionPage from "../pages/administrator/setting/contribution";
import SettingContributionForm from "../pages/administrator/setting/contribution/Form";
import ResidentContributionOtherForm from "../pages/contribution/other/Form";

/**
 * page components
 */

const authLogin = () => {
  console.log("authLogin", checkAuth());
  if (checkAuth()) {
    return redirect(pathUrl.navigation);
  }
  return null;
};
const authPage = () => {
  console.log("authPage", checkAuth());
  if (!checkAuth()) {
    return redirect(pathUrl.auth.login);
  }
  return null;
};

const PageRouter = createBrowserRouter(
  [
    {
      path: "/",
      loader: authPage,
      element: <Layout />,
      children: [
        {
          path: "/",
          element: <NavigationPage />,
        },
        {
          path: "resident",
          element: <ResidentPage />,
        },
        {
          path: "resident/new",
          element: <ResidentForm />,
        },
        {
          path: "resident/:houseId",
          element: <ResidentDetail />,
        },
        {
          path: "contribution/ipl",
          element: <ResidentFeeIplPage />,
        },
        {
          path: "contribution/other",
          element: <ResidentContributionOtherPage />,
        },
        {
          path: "contribution/other/new",
          element: <ResidentContributionOtherForm />,
        },

        {
          path: "cash/out",
          element: <CashOutPage />,
        },
        {
          path: "cash/report",
          element: <CashReportPage />,
        },

        {
          path: "administrator/user",
          element: <UserPage />,
        },
        {
          path: "administrator/user/:userId",
          element: <UserForm />,
        },
        {
          path: "administrator/user/new",
          element: <UserForm />,
        },
        {
          path: "administrator/house",
          element: <HousePage />,
        },
        {
          path: "administrator/house/new",
          element: <HouseForm />,
        },
        {
          path: "administrator/house/:houseId",
          element: <HouseForm />,
        },
        {
          path: "administrator/setting",
          element: <SettingPage />,
        },
        {
          path: "administrator/setting/contribution",
          element: <SettingContributionPage />,
        },
        {
          path: "administrator/setting/contribution/new",
          element: <SettingContributionForm />,
        },
      ],
    },
    {
      path: "auth",
      loader: authLogin,
      element: <LayoutAuth />,
      children: [
        {
          path: "login",
          element: <AuthLoginPage />,
        },
        {
          path: "verify-login/:phone/:verify_expired",
          element: <AuthLoginVerifyPage />,
        },
      ],
    },
  ],
  { basename: "/" }
);

const AppRouter = () => {
  return <RouterProvider router={PageRouter} />;
  // return PageRouter;
};
export default AppRouter;
